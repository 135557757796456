import React from 'react'
import { Button, Grid
 } from '@material-ui/core'
export default function Mia() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                Hi from Mia's Birthday!
            </Grid>
            <Grid item xs={12}>
                <Button href="https://diycandy.com/jagermeister-float-boozy-drink-recipe/" fullWidth variant="contained" color="secondary">
                    Click here to view recipe
                </Button>
            </Grid>
        </Grid>
    )
}
